<template>
  <div>
    <el-dialog
      :title="'替换门派'"
      :visible.sync="stageVisible"
      :close-on-click-modal="false"
      width="660px"
      v-if="ReplaceSect === 1"
      v-loading="loading"
      @closed="closed"
    >
      <el-form ref="stageForm" :model="stageData" label-width="200px">
        <el-form-item label="当前门派">
          {{
            `编号：${stageData.from_sect_id} 名称:${stageData.from_sect_name}`
          }}
        </el-form-item>
        <el-form-item
          label="替换门派"
          prop="to_sect_id"
          :rules="[
            {
              required: true,
              message: '请选择替换门派',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-select
            v-model="stageData.to_sect_id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入门派编号"
            :remote-method="remoteMethod"
            :loading="select_loading"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button type="primary" @click="switchGroup">确定</el-button>
          <el-button @click="stageVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "change_group",
  emits: ["changed", "closed", "getId"],
  computed: {
    groupsList: function () {
      let groups = this.groups.filter(
        (r) => r + "" != this.stageData.from_group + ""
      );
      return groups;
    },
  },
  props: {
    session_id: {
      type: String,
      required: false,
    },
    match_id: {
      type: String,
      required: true,
    },
    stage: {
      type: [String, Number],
      required: true,
    },
    from_data: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: false,
    },
    page_name: {
      type: String,
      required: false,
    },
  },
  watch: {
    from_data: {
      handler: function (val) {
        this.setData();
      },
      immediate: true,
    },
    $route: {
      handler: function (val) {
        this.type_id = val.query.type_id;
      },
      immediate: true,
    },
  },
  mounted() {
    this.setData();
  },
  data() {
    return {
      ReplaceSect: 1,
      groupInfo: {},
      loading: false,
      select_loading: false,
      options: [],
      stageVisible: false,
      stageData: {
        from_id: "",
        from_group: "",
        from_sect_id: "",
        from_sect_name: "",
        to_sect_id: "",
        from_season_name: "",
      },
      type_id: "s1",
    };
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    setData() {
      if (this.from_data) {
        this.stageData.from_id = this.from_data._id;
        this.stageData.from_group = this.from_data.group;
        this.stageData.from_sect_id = this.from_data.sect_id;
        this.stageData.from_sect_name = this.from_data.sect_name;
        this.stageData.from_season_name = this.from_data.season_name;
        this.stageData.from_type_id = this.from_data.type_id;
      } else {
        this.stageData = {
          from_id: "",
          from_group: "",
          from_sect_id: "",
          from_sect_name: "",
          to_sect_id: "",
        };
      }

      this.stageVisible = true;
    },
    async remoteMethod(query) {
      if (query !== "" && query.length == 6) {
        let saveData = {
          match_id: this.match_id,
          session_id: this.session_id,
          sect_ids: query,
          season_id: this.$route.query.season_id,
        };

        let res = await this.$http.match_subject.new_session_add_group(
          saveData
        );
        if (res.data != null) {
          let data = res.data[0];
          this.select_loading = false;
          this.getGroupInfo(data);
          this.groupInfo = data;
        }
      } else {
        this.options = [];
      }
      // this.select_loading = false;
    },
    getGroupInfo(data) {
      if (!data) {
        this.options = [];
      } else {
        let label = `${data.sect_id}|${data.sect_name}`;
        if (data.group && data.group > 0) {
          label += "|" + data.group + "组";
        }

        this.options = [
          {
            label: label,
            value: data.sect_id,
          },
        ];
      }
    },
    switchGroup() {
      this.$refs.stageForm.validate(async (valid) => {
        if (valid) {
          const combinedArray = {
            sect_id: this.stageData.from_sect_id, // 你需要提供一个值
            to_sect_id: this.stageData.to_sect_id, // 你需要提供另一个值
          };
          this.$emit("getId", combinedArray);
          this.$emit("changed", this.groupInfo);
          this.stageVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.to_sect_id {
  width: 200px;
}
</style>
